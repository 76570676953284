import React, { useState, useEffect } from "react";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import Dashnav from "../../components/dashnav";
import "./style.css";
import "../benchcandidate/sidebarStyle.css";
import ProfileDef from "../dashboard/profileIcon.svg";
import BenchIcon from "./DashIcon.svg";
import newCandidateIcon from "../dashboard/RegisterIcon.svg";
import ViewIcon from "../benchcandidate/view-icon.svg";
import EditIcon from "../benchcandidate/edit-icon.svg";
import CandidateDetailsModal from "../benchcandidate/CandidateDetailsModal"; // Your new modal component
import CandidateModal from "../benchcandidate/candidateModal";
import { ReactComponent as NameUserIcon } from "../../images/name-user-icon.svg";
import Profile from "../../images/your-profile.svg";
import AddIcon from "../../images/add-btn-icon.svg";
import { ReactComponent as CandidateNameIcon } from "../../images/candidate-name.svg";
import { ReactComponent as EmailIcon } from "../../images/email.svg";
import { ReactComponent as VisaIcon } from "../../images/visa-icon.svg";
import { ReactComponent as CompanyIcon } from "../../images/company.svg";
import { ReactComponent as ActionIcon } from "../../images/actions-icon.svg";
import { ReactComponent as EditIconButton } from "../../images/icon-edit.svg";
import { ReactComponent as StatusIcon } from "../../images/status.svg";
import { ReactComponent as RightTickIcon } from "../../images/icon-right-tick.svg";
import { ReactComponent as DeleteIcon } from "../../images/icon-delete.svg";
import { ReactComponent as Female } from "../../images/female.svg";
import { ReactComponent as Others } from "../../images/otherGender.svg";
import BenchDetails from "../benchcandidate/BenchCandidateDetails";
import { useGlobalContext } from "../../globalContext";

const MyAssignedCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [searchTermAssigned, setsearchTermAssigned] = useState("");
  const [visaStatusFilter, setVisaStatusFilter] = useState("");
  const [setLocationFilterAssigned, setsetLocationFilterAssigned] = useState("");
  const [candidateCount, setCandidateCount] = useState("");
  const [recruitersList, setRecruitersList] = useState([]); // State for recruiters list
  const [teamsFilter, setTeamsFilter] = useState("");
  const [currentStatusAssigned, setcurrentStatusAssigned] = useState("");
  const { isLoggedIn, login, user } = useAuth();
  const [dataDelete, setDataDelete] = useState(false);
  const [openDetails, setOPenDetails] = useState(false);
  const navigate = useNavigate();
  let storedUser = JSON.parse(localStorage.getItem("user"));

  // ========================================================================

  useEffect(() => {
    if (storedUser?.email) {
      fetch("https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailid: storedUser.email,
          assigned_recruiter_email: storedUser.email,
          role: "recruiter",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const candidates = Object.entries(data).map(([key, value]) => {
            const id = key.includes(":") ? key.split(": ")[1] : key;
            return {
              id,
              ...value,
            };
          });
          console.log("Transformed candidates: ", candidates);

          setCandidates(candidates); // Set the candidates state
          setDataDelete(false);
        })
        .catch((error) => console.error("Error fetching candidates:", error));
    }
  }, [storedUser?.email]);

  // Fetch recruiters list from the API
  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        const response = await fetch(
          "https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailid: storedUser.email,
              role: "admin",
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // Extract unique recruiters list with their ID
          const recruiters = Object.entries(data).map(([key, value]) => ({
            id: key.includes(":") ? key.split(": ")[1] : key, // Extract id
            assigned_recruiter: value.assigned_recruiter || "Unknown", // Extract assigned_recruiter or default to "Unknown"
          }));

          // Update state with the extracted data
          setRecruitersList(recruiters);
          console.log("Recruiters List:", recruiters);
        } else {
          console.error("Failed to fetch recruiters:", data.message);
        }
      } catch (error) {
        console.error("Error fetching recruiters:", error);
      }
    };

    if (storedUser?.email) {
      fetchRecruiters();
    }
  }, [storedUser?.email]);

  useEffect(() => {
    let filtered = candidates;

    // Search by name
    if (searchTermAssigned) {
      filtered = filtered.filter(
        (candidate) =>
          candidate.first_name.toLowerCase().includes(searchTermAssigned.toLowerCase()) ||
          candidate.last_name.toLowerCase().includes(searchTermAssigned.toLowerCase())
      );
    }

    // Filter by visa status
    if (visaStatusFilter) {
      filtered = filtered.filter((candidate) => {
        // If the visa status is "OPT", also check the "OPT Letter Provided" status
        if (visaStatusFilter === "OPT") {
          return candidate.visa_status === "OPT";
        }
        // Otherwise, just filter by the visa status
        return candidate.visa_status === visaStatusFilter;
      });
    }

    // Filter by Current status
    if (currentStatusAssigned) {
      filtered = filtered.filter((candidate) => candidate.current_status === currentStatusAssigned);
    }
    // Filter by assigned teams
    if (teamsFilter) {
      filtered = filtered.filter((candidate) => {
        // Ensure to handle both string and array formats for assigned_team
        if (Array.isArray(candidate.assigned_team)) {
          return candidate.assigned_team.includes(teamsFilter);
          // console.log(`filter1 ${candidate.assigned_team}`);
        } else {
          return candidate.assigned_team === teamsFilter;
          // console.log(`filter1 ${candidate.assigned_team}`);
        }
      });
    }

    // Filter by location preference
    if (setLocationFilterAssigned) {
      filtered = filtered.filter((candidate) => candidate.location_preference === setLocationFilterAssigned);
    }

    setFilteredCandidates(filtered);
  }, [searchTermAssigned, visaStatusFilter, setLocationFilterAssigned, currentStatusAssigned, teamsFilter, candidates]);

  const openModal = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const closeModal = () => {
    setSelectedCandidate(null);
  };

  const saveCandidate = (updatedCandidate) => {
    // Update candidate in the state
    const updatedCandidates = candidates.length
      ? candidates.map((candidate) => (candidate.id === updatedCandidate.id ? updatedCandidate : candidate))
      : [];
    setCandidates(updatedCandidates);

    // Close the modal
    closeModal();

    // POST request to update candidate in the database
    fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailid: storedUser.email,
        query: `UPDATE foursphere_recruiters.bench_candidates 
                        SET first_name = '${updatedCandidate.first_name}', 
                            last_name = '${updatedCandidate.last_name}', 
                            primary_email = '${updatedCandidate.primary_email}', 
                            secondary_email = '${updatedCandidate.secondary_email}', 
                            primary_contact = '${updatedCandidate.primary_contact}', 
                            secondary_contact = '${updatedCandidate.secondary_contact}', 
                            location_preference = '${updatedCandidate.location_preference}', 
                            visa_status = '${updatedCandidate.visa_status}', 
                            current_status = '${updatedCandidate.current_status}', 
                            assigned_recruiter = '${updatedCandidate.assigned_recruiter}', 
                            currently_in_project = '${updatedCandidate.currently_in_project}', 
                            availability = '${updatedCandidate.availability}', 
                            candidate_onedrive_link = '${updatedCandidate.candidate_onedrive_link}', 
                            comments = '${updatedCandidate.comments}', 
                            cvisa_status = '${updatedCandidate.cvisa_status}', 
                            opt_letter_status = '${updatedCandidate.opt_letter_status}', 
                            primary_technology = '${updatedCandidate.primary_technology}', 
                            secondary_technology = '${updatedCandidate.secondary_technology}',
                            assigned_team = '${updatedCandidate.assigned_team}' 
                        WHERE id = ${updatedCandidate.id};`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Candidate updated successfully");
        } else {
          console.error("Error updating candidate:", response.statusText);
        }
      })
      .catch((error) => console.error("Error updating candidate:", error));
  };

  function goToRegister() {
    navigate("/registerNewCandidates");
  }

  useEffect(() => {
    // Fetch the number of active bench candidates from the API
    fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailid: storedUser.email,
        query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.bench_candidates WHERE current_status = 'Active';`,
      }),
    })
      .then((response) => response.json())
      .then((data) => setCandidateCount(data[0].count)) // Assuming the API returns an array with count as the first element
      .catch((error) => console.error("Error fetching candidate count:", error));
  }, []);

  const sortCandidatesByFirstName = (candidates) => {
    return candidates.sort((a, b) => {
      // Safely check if first_name exists, sort alphabetically by first_name
      const nameA = a.first_name ? a.first_name.toLowerCase() : "";
      const nameB = b.first_name ? b.first_name.toLowerCase() : "";

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  // Example usage in your component
  const sortedCandidates = sortCandidatesByFirstName(filteredCandidates);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // Modal state
  const [currentCandidate, setCurrentCandidate] = useState(null); // Candidate data
  const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false); // Subview modal state

  // Function to open the details modal and fetch data
  const handleOpenDetailsModal = (candidateEmail) => {
    {
      // Fetch candidates data on component mount
      fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailid: storedUser.email,
          query: `SELECT cr.email, cr.from_email, cr.to_email, cr.candidate_full_name, cr.client_name, cr.rate, cr.submission_status, MAX(cr.submission_date) as latest_submission_date    FROM foursphere_recruiters.candidate_rateconfirmations cr   JOIN foursphere_recruiters.bench_candidates bc  ON cr.email = bc.primary_email OR cr.email = bc.secondary_email    WHERE cr.email = '${candidateEmail}'     GROUP BY cr.email, cr.from_email, cr.to_email, cr.candidate_full_name, cr.client_name, cr.rate, cr.submission_status   ORDER BY cr.email ASC, latest_submission_date DESC`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.length > 0) {
            setCurrentCandidate(data); // Set candidate data
            setIsDetailsModalOpen(true); // Open the details modal
          }
        })
        .catch((error) => console.error("Error fetching candidate details:", error));
    }
  };

  // Function to open the additional modal/subview
  const handleOpenAdditionalModal = () => {
    setIsAdditionalModalOpen(true); // Open additional modal/subview
  };

  // Close functions for both modals
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
  const handleCloseAdditionalModal = () => setIsAdditionalModalOpen(false);

  //delete the hovered bench Candidate
  const deleteCandidate = async (candidate) => {
    if (window.confirm("Are you sure you want to delete")) {
      try {
        const response = await fetch(
          `https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailid: storedUser.email,
              operation: "delete",
              id: `${candidate}`,
            }),
          }
        );
        if (response.ok) {
          // alert candidate is deleted and list is updated
          alert("clicked candidate is Deleted successfully and list is updated");
          setDataDelete(true);
        } else {
          throw new Error("failed to delete candidates");
        }
      } catch (error) {
        console.error("Error deleting candidate:" + error.message);
      }
    }
  };

  function candidateAddButton() {
    navigate("/registerNewCandidates");
  }

  const navigateCandidatDetails = (id) => {
    // navigate(`/details?id=${id}`);

    navigate(`?id=${id}`);
    setOPenDetails(true);
  };

  const handleStatusChange = (event, candidate) => {
    const updatedStatus = event.target.value;
    console.log("candidtefetchdatta", candidate);

    // Update status in the database using candidate id
    fetch("https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        emailid: storedUser.email,
        onboarded_company: updatedStatus,
        role: "admin",
        id: candidate.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Status updated:", data);

        // Update the local state to reflect the change based on id
        setFilteredCandidates((prevCandidates) =>
          prevCandidates.map((c) => (c.id === candidate.id ? { ...c, onboarded_company: updatedStatus } : c))
        );
      })
      .catch((error) => console.error("Error updating status:", error));
  };

  const ProfileOpen = () => {
    // Open the profile page
    navigate("/userProfile");
    // or navigate("/register", { replace: true }); // replace current URL with new one, without adding it to the history stack
  };

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 15;

  const totalPages = Math.ceil(sortedCandidates.length / jobsPerPage);

  // Calculate the start and end indices for the current page
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  // Get the sortedCandidates for the current page
  const currentJobs = sortedCandidates.slice(indexOfFirstJob, indexOfLastJob);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageInputChange = (e) => {
    const pageNum = Number(e.target.value);
    if (pageNum >= 1 && pageNum <= totalPages) {
      setCurrentPage(pageNum);
    }
  };

  useEffect(() => {
    handlePageChange(currentPage);
  }, [currentPage]);

  return (
    <div>
      <div className="main-dash row-flex">
        <Dashnav />
        {openDetails == false && (
          <section className="w-100 scroll-bar bottom-sidebar">
            <div className="top-section row-flex">
              <img src={BenchIcon} alt="" />
              <p className="title-left">My Assigned Candidates</p>
              <button className="profile-button-right" onClick={ProfileOpen}>
                <img src={Profile} alt="" className="profile" />
                Your Profile
              </button>
            </div>

            <div className="filters row-flex">
              <div className="filters-left">
                <input
                  type="text"
                  placeholder="Search by name"
                  value={searchTermAssigned}
                  onChange={(e) => setsearchTermAssigned(e.target.value)}
                />

                <select value={visaStatusFilter} onChange={(e) => setVisaStatusFilter(e.target.value)}>
                  <option value="">Filter By Visa</option>
                  <option value="OPT">OPT</option>
                  <option value="CPT">CPT</option>
                  <option value="H1B">H1B</option>
                  <option value="H4 EAD">H4 EAD</option>
                  <option value="GC">GC</option>
                  <option value="GC EAD">GC EAD</option>
                  <option value="USC">USC</option>
                  <option value="STEM OPT">STEM OPT</option>
                </select>

                <select
                  type="text"
                  placeholder="current Status"
                  value={currentStatusAssigned}
                  onChange={(e) => setcurrentStatusAssigned(e.target.value)}
                >
                  <option value="">Filter by Status</option>
                  {[...new Set(candidates.map((item) => item.current_status))].map((statusActive, index) => (
                    <option key={index} value={statusActive}>
                      {statusActive}
                    </option>
                  ))}
                </select>

                <select
                  type="text"
                  placeholder="Filter by Teams"
                  value={teamsFilter}
                  onChange={(e) => setTeamsFilter(e.target.value)}
                >
                  <option value="">Select Teams</option>

                  {[...new Set(candidates.map((item) => item.assigned_team))].map((team, index) => (
                    <option key={index} value={team}>
                      {team}
                    </option>
                  ))}
                </select>

                <select
                  value={setLocationFilterAssigned}
                  onChange={(e) => setsetLocationFilterAssigned(e.target.value)}
                >
                  <option value="">Filter by Location Preference</option>
                  {[...new Set(candidates.map((item) => item.location_preference))].map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filters-right">
                {user?.user_role === "recruiter" ? (
                  <p>
                    <button>
                      <img src={AddIcon} />
                    </button>
                  </p>
                ) : (
                  <button>
                    <img src={AddIcon} onClick={candidateAddButton} />
                  </button>
                )}

                <div>Candidates: {sortedCandidates.length}</div>
              </div>
            </div>

            <div className="mainContent">
              <div className="table-container">
                <table className="dynamic-table-data">
                  <thead>
                    <tr>
                      <th>
                        <div className="alignCenter">
                          <CandidateNameIcon />
                          Candidate Name
                        </div>
                      </th>
                      <th>
                        <div className="alignCenter">
                          <EmailIcon />
                          Email Id
                        </div>
                      </th>
                      <th>
                        <div className="alignCenter">
                          <VisaIcon />
                          Visa Status
                        </div>
                      </th>
                      <th>
                        <div className="alignCenter">
                          <CompanyIcon />
                          Onboarded Company
                        </div>
                      </th>
                      <th>
                        <div className="alignCenter">
                          <StatusIcon />
                          Status
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentJobs.map((candidate) => (
                      <tr>
                        <td
                          className={`status-change-statuschange ${
                            candidate.gender === "Male" ? "Male" : candidate.gender === "Female" ? "Female" : "Other"
                          }`}
                          onClick={() => navigateCandidatDetails(candidate.id)}
                        >
                          <div className="alignCenter">
                            {candidate.gender === "Male" ? (
                              <NameUserIcon />
                            ) : candidate.gender === "Female" ? (
                              <Female />
                            ) : (
                              <Others />
                            )}

                            <span>
                              {candidate.first_name} {candidate.last_name || " "}
                            </span>
                          </div>
                        </td>

                        <td>{candidate.primary_email || "N/A"}</td>

                        <td>
                          <span className="visa-change">{candidate.visa_status || "N/A"}</span>
                        </td>

                        {/* <td>{candidate.onboarded_company}</td> */}
                        <td className="onboarded-data">
                          <select
                            className="company-data"
                            value={candidate.onboarded_company}
                            onChange={(e) => handleStatusChange(e, candidate)}
                            disabled
                          >
                            <option value="">Select Option</option>
                            <option value="4Sphere">4Sphere</option>
                            <option value="tb soft">TB Soft</option>
                            <option value="ub soft">UB Soft</option>
                          </select>
                        </td>

                        <td
                          className={` ${
                            candidate.current_status === "Active"
                              ? "Active"
                              : candidate.current_status === "Inactive"
                              ? "InActive"
                              : "Unknown"
                          }`}
                        >
                          <div className="status-change">
                            <span>{candidate.current_status || " "}</span>
                            {candidate.current_status === "Active" ? (
                              <RightTickIcon />
                            ) : candidate.current_status === "Inactive" ? (
                              <RightTickIcon />
                            ) : (
                              <i className=" "></i>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <div className="row-flex navigation" style={{ marginTop: "10px" }}>
                    <button
                      className="left nav"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                        <path
                          d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z"
                          fill="#341FA8"
                        />
                      </svg>
                    </button>
                    <span style={{ margin: "0 10px" }} className="text">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="nav"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                        <path
                          d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z"
                          fill="#341FA8"
                        />
                      </svg>
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </section>
        )}

        {openDetails && <BenchDetails openDetails={openDetails} setOPenDetails={setOPenDetails} />}
      </div>
      {isDetailsModalOpen && currentCandidate && (
        <CandidateDetailsModal candidate={currentCandidate} onClose={handleCloseDetailsModal} />
      )}

      {selectedCandidate && (
        <CandidateModal candidate={selectedCandidate} onClose={closeModal} onSave={saveCandidate} />
      )}
    </div>
  );
};
export default MyAssignedCandidates;
