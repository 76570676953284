import React, { useState, useEffect } from "react";
import Dashnav from "../../components/dashnav";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../images/info.svg";
import Sidenav from "../../images/side_top.svg";
import General from "../../images/general.svg";
import Others from "../../images/others.svg";
import saveTick from "../../images/saveTick.svg";
import { useAuth } from "../../authContext";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./registerIntv.css";
const RegisterActiveInterviews = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  function backEditCandidate() {
    navigate("/activeInterviews");
  }
  // const { isLoggedIn, login, user, choosenCandidate,
  //     setChoosenCandidate, setCandidate,
  //     candidateList, setCandidateList,
  //     candidateId, setCandidateId } = useAuth();
  const [candidateList, setCandidateList] = useState([]);
  const [choosenCandidate, setChoosenCandidate] = useState("");
  const [recruitersList, setRecruitersList] = useState([]); // State for recruiters list

  const [recruiter, setRecruiter] = useState("");
  const [errors, setErrors] = useState({});
  const [emailId, setEmailId] = useState("");
  const [comments, setComments] = useState("");
  const [Rate, setRate] = useState("");
  const [Technology, setTechnology] = useState("");
  const [Vendor, setVendor] = useState("");
  const [ClientName, setClientName] = useState("");
  const [InterviewPanel, setInterviewPanel] = useState("");
  const [Timezone, setTimezone] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [formData, setFormData] = useState({
    submission_date: null,
    candidate_email_id: "",
    from_email: "",
  });
  function candidateNameSet(e) {
    const selectedEmail = e.target.value;
  }
  const handleCandidateChange = (e) => {
    const selectedEmail = e.target.value;

    const selectedCandidate = candidateList.find((item) => item.full_name === selectedEmail);
    setChoosenCandidate(selectedCandidate.full_name);

    if (selectedCandidate) {
      setEmailId(selectedCandidate.primary_email);
      setRecruiter(selectedCandidate.assigned_recruiter || "");
    }
  };

  const [formDataMar, setFormDataMar] = useState({
    interview_date_time: null,
  });

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    let storedUser = JSON.parse(localStorage.getItem("user"));

    try {
      const queryObj = {
        emailid: storedUser.email,
        email: storedUser.email,
      };
      console.log("Sending query:", queryObj); // Log the query object

      const response = await fetch(
        "https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Details_Min_v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(queryObj),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCandidateList(data);
      console.log("Received data:", data); // Log the received data
    } catch (error) {
      // setError(error.message);
      console.error("Error fetching jobs:", error); // Log the error
    }
  };

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        const response = await fetch(
          "https://us-east1-recruiterportal.cloudfunctions.net/Retrieve_Recruiter_Details_v3",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailid: user.email,
              operation: "retrieve",
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // Extract unique recruiters list with their ID
          const recruiters = data.data.map((item) => ({
            // Extract id
            recruiter_alias_name: item.recruiter_alias_name || "Unknown", // Extract assigned_recruiter or default to "Unknown"
          }));

          // Update state with the extracted data
          setRecruitersList(recruiters);
          console.log("Recruiters List:", recruiters);
        } else {
          console.error("Failed to fetch recruiters:", data.message);
        }
      } catch (error) {
        console.error("Error fetching recruiters:", error);
      }
    };

    fetchRecruiters();
  }, []);

  const handleRegisterCandidate = async () => {
    console.log("choosenCandidate", choosenCandidate);

    // Insert query with NULL handling for secondary_contact and other fields
    const payload = {
      operation: "insert",
      // emailid : user.email,
      // gender: `${gender || ''}`,
      candidate_full_name: `${choosenCandidate || ""}`,
      candidate_email_id: `${emailId || ""}`,
      from_email: `${recruiter || ""}`,
      rate: `${Rate || ""}`,
      submission_date: `${formData.submission_date || ""}`,
      submission_status: `${currentStatus || ""}`,
      technology: `${Technology || ""}`,
      to_email: `${Vendor || ""}`,
      client_name: `${ClientName || ""}`,
      comments: `${comments || ""}`,
      interview_date_time: `${formDataMar.interview_date_time || ""}`,
      interview_panel_members: `${InterviewPanel || ""}`,
      interview_timezone: `${Timezone || ""}`,
    };

    try {
      const response = await fetch(
        "https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Interview_Details_v3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        toast.success("Active Interview Registered Successfully", {
          draggable: "true",
          autoClose: 2000,
          // position: "center",
          className: "toast-center",
        });
        // Optionally, redirect to another page or reset form
        // navigate('/benchcandidates'); // Replace with your desired route
      } else {
        const errorData = await response.json();
        toast.error(`Registration failed: ${errorData.message || "An unknown error occurred."},`, {
          draggable: "true",
          autoClose: 2000,
          // position: "center",
          className: "toast-center",
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input" onClick={onClick} ref={ref}>
      <input type="text" value={value || ""} placeholder="mm/dd/yyyy" readOnly className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  ));

  // Validate and format the US date input
  const handleDateChange = (date) => {
    const formattedDate = date ? format(date, "MM/dd/yyyy") : null;
    console.log("formattedDate", formattedDate);

    setFormData({ ...formData, submission_date: formattedDate });
    console.log("formData", formData);
  };

  const CustomInputMarketing = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input" onClick={onClick} ref={ref}>
      <input type="text" value={value || ""} placeholder="mm/dd/yyyy hh:mm:ss" readOnly className="date-input" />
      <FaCalendarAlt className="calendar-icon" />
    </div>
  ));

  const handleDateChangemarketing = (date) => {
    // Format date with time
    const formattedDateMarketing = date ? format(date, "MM/dd/yyyy HH:mm:ss") : null;

    setFormDataMar({
      ...formDataMar,
      interview_date_time: formattedDateMarketing,
    });
    console.log("formData Marketing:", formattedDateMarketing);
  };

  return (
    <div className="main-dash row-flex">
      <Dashnav />
      <div className="activeIntvRegister scroll-bar bottom-sidebar">
        <div className="header-active">
          <img src={Sidenav} alt="" srcset="" onClick={backEditCandidate} />
          <img src={Info} alt="" srcset="" />
          <h2 className="title">Register Active Interviews</h2>
        </div>

        <div className="mainContent-activeIntv">
          <section>
            <div className="legendHeading-activeIntv">
              <img src={General} alt="" srcset="" />
              <h5>General</h5>
              <span className="divider"></span>
            </div>
            <div className="gridActiveIntv">
              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Candidate Full Name<span>*</span>
                </label>
                <select className="" onChange={handleCandidateChange} value={choosenCandidate}>
                  <option value="">Select Candidate</option>
                  {candidateList.map((item) => (
                    <option key={item.primary_email} value={item.full_name}>
                      {item.full_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">Candidate Email Id</label>
                <input
                  type="text"
                  placeholder="Enter Candidate Name"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                {errors.emailId && <div className="error">{errors.emailId}</div>}
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Assigned Recruiter<span>*</span>
                </label>
                <select value={recruiter} onChange={(e) => setRecruiter(e.target.value)}>
                  <option value="">Select Recruiter</option>
                  {candidateList.map(
                    (item) =>
                      item.assigned_recruiter && (
                        <option key={item.primary_email} value={item.assigned_recruiter}>
                          {item.assigned_recruiter}
                        </option>
                      )
                  )}
                </select>
                {errors.recruiter && <div className="error">{errors.recruiter}</div>}
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Rate<span>*</span>
                </label>
                <input type="text" placeholder="Enter Rate" value={Rate} onChange={(e) => setRate(e.target.value)} />
                {errors.Rate && <div className="error">{errors.Rate}</div>}
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Submission Date<span>*</span>
                </label>
                <DatePicker
                  selected={formData.submission_date ? new Date(formData.submission_date) : null}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="mm/dd/yyyy"
                  name="submission_date"
                  customInput={<CustomInput />}
                />
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Submission Status<span>*</span>
                </label>
                <select
                  className="company-data"
                  value={currentStatus}
                  onChange={(e) => setCurrentStatus(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="submitted to vendor">submitted to vendor</option>
                  <option value="under review">under review</option>
                  <option value="Shortlisted">Shortlisted</option>
                  <option value="Not Shortlisted">Not Shortlisted</option>
                  <option value="Technical Screening">Technical Screening</option>
                  <option value="Interview Round 1">Interview Round 1</option>
                  <option value="Interview Round 2">Interview Round 2</option>
                  <option value="Interview Round 3">Interview Round 3</option>
                  <option value="Interview Rejected">Interview Rejected</option>
                </select>
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">Technology</label>
                <input
                  type="text"
                  placeholder="Enter Technology"
                  value={Technology}
                  onChange={(e) => setTechnology(e.target.value)}
                />
                {errors.Technology && <div className="error">{errors.Technology}</div>}
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Vendor<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Vendor"
                  value={Vendor}
                  onChange={(e) => setVendor(e.target.value)}
                />
                {errors.Vendor && <div className="error">{errors.Vendor}</div>}
              </div>
            </div>
          </section>

          <section>
            <div className="legendHeading-activeIntv">
              <img src={Others} alt="" srcset="" />
              <h5>Interview Details</h5>
              <span className="divider-intv"></span>
            </div>
            <div className="gridActiveIntv">
              {/* <div className='inputComponentActiveIntv'> */}

              <div className="inputComponentActiveIntv">
                <label htmlFor="">
                  Client Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Client Name"
                  value={ClientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
                {errors.ClientName && <div className="error">{errors.ClientName}</div>}
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="interview_date_time">Interview Date Time</label>
                <DatePicker
                  selected={formDataMar.interview_date_time ? new Date(formDataMar.interview_date_time) : null}
                  onChange={handleDateChangemarketing}
                  showTimeSelect // Enable time selection
                  timeFormat="HH:mm"
                  timeIntervals={15} // Intervals for time picker (15 mins in this case)
                  dateFormat="MM/dd/yyyy HH:mm:ss" // Display format
                  placeholderText="mm/dd/yyyy hh:mm:ss"
                  name="interview_date_time"
                  customInput={<CustomInputMarketing />}
                />
              </div>

              <div className="inputComponentActiveIntv">
                <label htmlFor="">Interview Timezone</label>
                <select value={Timezone} onChange={(e) => setTimezone(e.target.value)}>
                  <option value="">Select TimeZone</option>
                  <option value="CST">CST</option>
                  <option value="EST">EST</option>
                  <option value="MST">MST</option>
                </select>
              </div>
            </div>
          </section>

          <section>
            {/* <div className='legendHeading-activeIntv'>
                            <img src={Others} alt="" srcset="" />
                            <h5>Other</h5>
                            <span className='divider'></span>
                        </div> */}
            <div className="gridActiveIntv">
              <div className="inputContent">
                <label htmlFor="">Comment</label>
                <textarea
                  id="comments"
                  name="comments" // Add a name attribute to match state
                  rows="5" // Correct the attribute from `row` to `rows`
                  className="textarea-reason"
                  placeholder="Enter Comment"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="gridActiveIntv">
              <div className="inputContent1">
                <label htmlFor="">Interview Panel Member</label>
                <textarea
                  type="text"
                  rows="5"
                  className="textarea-reason"
                  placeholder="Enter Interview Panel Member"
                  value={InterviewPanel}
                  onChange={(e) => setInterviewPanel(e.target.value)}
                ></textarea>
                {errors.InterviewPanel && <div className="error">{errors.InterviewPanel}</div>}
              </div>
            </div>
          </section>
        </div>
        <button className="saveBtnIntv" onClick={handleRegisterCandidate}>
          <img src={saveTick} alt="saveTickIcon" />
          Register Candidate
        </button>
      </div>
    </div>
  );
};

export default RegisterActiveInterviews;
