import React, { useState, useEffect } from "react";
import { useAuth } from "../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import "./jobver0.1.css";
import JobBanner from "./job-banner.png";
import AiIcon from "./aicomp.png";
import FilterIcon from "./filterIcon.png";
import CopyIcon from "./copyIcon.png";
import DocUpdate from "./doc-update.png";
import AiGifIcon from "./aiIcon.gif";
import CandidateIcon from "../jobSearch.svg";
import Loader from "../../../components/Loader";

const ActiveJobs = () => {
  const {
    candidateDetails,
    setCandidateDetails,
    isLoggedIn,
    user,
    sessionToken,
    fileSelect,
    logSessionId,
    choosenCandidate,
    setCandidate,
    candidate,
    candidateList,
    setCandidateList,
    candidateId,
    setCandidateId,
  } = useAuth();
  const [jobList, setJobList] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [content, setContent] = useState(null);
  const [activeJobId, setActiveJobId] = useState(null);
  const [filter, setFilter] = useState(false);
  // const [activeFilter, setActiveFilter] = useState(null); // State for tracking the active filter
  const [aiResponse, setAiResponse] = useState(null); // State for tracking AI response
  const [aiPopup, setAiPopup] = useState(null); // State for AI popup
  const navigate = useNavigate();
  const [selectId, setSelectId] = useState(null); // State for
  // const [aiready, setAiready] = useState(false); // State for
  // const [selectedCity, setSelectedCity] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  // const [resumePopup, setResumePopup] = useState(null);
  // const [resumeResponse, setresumeResponse] = useState(null);
  const [resumeNotFound, setResumeNotFound] = useState(false);
  const [activeFilters, setActiveFilters] = useState({}); // State for tracking the active filter and with multiple filters suppert

  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [buttonText, setButtonText] = useState("Apply");

  useEffect(() => {
    const uniqueCities = [...new Set(jobList.map((job) => job.job_city))];
    const uniqueStates = [...new Set(jobList.map((job) => job.job_state))];
    setCityOptions(uniqueCities);
    setStateOptions(uniqueStates);
  }, [jobList]);

  useEffect(() => {
    if (user?.email) {
      fetchCandidates();
    }
  }, [user?.email]);

  useEffect(() => {
    if (candidateDetails?.primary_email) {
      activeFilters.primary_email = candidateDetails.primary_email;
      setActiveFilters({ ...activeFilters });
    }
  }, [candidateDetails?.primary_email]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoggedIn === false) {
        navigate("/");
      }
      console.log(isLoggedIn);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isLoggedIn, navigate, choosenCandidate]);

  const applyFilter = (key, value) => {
    if (key === "remote") {
      if (!value) {
        delete activeFilters[key];
      } else {
        activeFilters[key] = value;
      }

      return setActiveFilters({ ...activeFilters });
    }

    setActiveFilters((prevFilters) => {
      if (isActiveFilter(key, value)) {
        const newFilters = { ...prevFilters };
        delete newFilters[key];
        return newFilters;
      } else {
        return { ...prevFilters, [key]: value };
      }
    });
  };

  // const removeFilter = (key) => {
  //   setActiveFilters((prevFilters) => {
  //     const newFilters = { ...prevFilters };
  //     delete newFilters[key];
  //     return newFilters;
  //   });
  // };

  const getFilteredJobs = () => {
    return jobList.filter((job) => {
      const matchesFilters = Object.entries(activeFilters).every(([key, value]) => {
        if (key === "date") return new Date(job.job_posted_at_datetime_utc) > value;
        if (key === "state") return job.job_state === value;
        if (key === "city") return job.job_city === value;
        if (key === "remote") return job.job_is_remote === value;
        return true;
      });

      const matchesSearch =
        searchTerm === "" ||
        job.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.employer_name.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesFilters && matchesSearch;
    });
  };

  const clearFilters = () => {
    setActiveFilters({});
    setSearchTerm("");
    if (jobList.length > 0) {
      setContent(jobList[0]);
      setActiveJobId(jobList[0].id);
    }
  };

  useEffect(() => {
    setFilteredJobList(getFilteredJobs());
    setFilter(Object.keys(activeFilters).length > 0 || searchTerm !== "");
  }, [activeFilters, jobList, searchTerm]);

  // Usage:
  const filterLast24Hours = () => applyFilter("date", new Date(new Date().setDate(new Date().getDate() - 1)));
  const filterLast3Days = () => applyFilter("date", new Date(new Date().setDate(new Date().getDate() - 3)));
  const filterLast7Days = () => applyFilter("date", new Date(new Date().setDate(new Date().getDate() - 7)));
  const filterByState = (state) => applyFilter("state", state);
  const filterByCity = (city) => applyFilter("city", city);
  const filterRemoteJobs = () => applyFilter("remote", !activeFilters.remote);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      clearFilters();
    }
  };

  const isActiveFilter = (key, value) => {
    if (key === "date") {
      return activeFilters[key]?.toDateString() === value.toDateString();
    }
    return activeFilters[key] === value;
  };

  const fetchCandidates = async () => {
    try {
      const queryObj = {
        email: user?.email,
      };
      console.log("Sending query:", queryObj); // Log the query object

      const response = await fetch(
        "https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Candidate_Details_Min_v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(queryObj),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCandidateList(data);
      console.log("Received data:", data); // Log the received data
    } catch (error) {
      // setError(error.message);
      console.error("Error fetching jobs:", error); // Log the error
    }
  };
  const fetchJobs = async () => {
    try {
      if (!user?.email) {
        console.error("User email is not available.");
        return; // Exit if user email is not available
      }

      const queryObj = {
        emailid: user?.email,
        email_id: choosenCandidate,
      };
      console.log("Sending query:", queryObj); // Log the query object

      const response = await fetch(
        "https://us-east1-recruiterportal.cloudfunctions.net/Get_Jobs_From_Database_Comparing_Resume_v3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(queryObj),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("other data:", data); // Log the received data
      setJobList(data.matches);
      setSelectId(data.matches[0]?.job_id || null); // Use optional chaining in case data is empty
      // setLoading(false);
      // setError(null);

      // Set default content and active job
      if (data.matches.length > 0) {
        setContent(data.matches[0]);
        setActiveJobId(data.matches[0].job_id);
        console.log(activeJobId);
      } else {
        console.log("No results found for the given email and skills."); // Log if no results
      }
    } catch (error) {
      // setError(error.message);
      // setLoading(false);
      setResumeNotFound(true);
      console.error("Error fetching jobs:", error); // Log the error
    }
  };

  function profileOpen() {
    window.location.href = `/details?id=${candidateId}`;
  }

  useEffect(() => {
    // setLoading(true);
    setJobList([]);
    setContent(null);
    setTimeout(() => {
      if (choosenCandidate !== "") {
        fetchJobs();
      } else {
        // window.location.href = "/jobSearch";
      }
    }, 600);
  }, [sessionToken, user, choosenCandidate]);

  useEffect(() => {
    if (choosenCandidate && candidateList?.length) {
      handleUserChange(choosenCandidate);
    }
  }, [choosenCandidate, candidateList?.length]);

  useEffect(() => {
    if (candidateDetails?.primary_email && selectId && user?.email) {
      checkButtonStatus();
    }
  }, [candidateDetails?.primary_email, selectId, user?.email]);

  const handleJobClick = (job) => {
    setContent(job);
    setActiveJobId(job.job_id);
    setSelectId(job.job_id);
    setAiResponse(null);
    // setresumeResponse(null);
  };

  const clearFilter = () => {
    setFilteredJobList([]);
    setFilter(false);
    // setActiveFilter(null); // Clear active filter
    if (jobList.length > 0) {
      setContent(jobList[0]);
      setActiveJobId(jobList[0].id);
    }
  };

  /* --------------------- Apply now Script Starts --------------------------------*/

  const applyButton = async () => {
    try {
      if (!user?.email) {
        console.error("User email is not available.");
        return; // Exit if user email is not available
      }
      const appliedResponse = await fetch(
        "https://us-east1-recruiterportal.cloudfunctions.net/check_apply_reapply_buttons_v3",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            recruiter_email: user?.email,
            candidate_email: candidateDetails?.primary_email,
            payload: {
              job_id: selectId,
            },
            function_name: "clicked on Apply now",
          }),
        }
      );

      if (!appliedResponse.ok) {
        throw new Error("Failed to check job application status");
      }

      await appliedResponse.json();
      checkButtonStatus();
    } catch (error) {
      console.error("Error checking apply status:", error);
    }
  };

  const checkButtonStatus = () => {
    fetch("https://us-east1-recruiterportal.cloudfunctions.net/check_apply_reapply_buttons_v3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recruiter_email: user?.email,
        candidate_email: candidateDetails?.primary_email,
        job_id: selectId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        let btnText = data.result == "Apply Button" ? "Apply " : "Reapply ";
        setButtonText(btnText);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* --------------------- Apply now Script Ends --------------------------------*/

  const apiEndpointTwo = "https://us-east1-foursssolutions.cloudfunctions.net/resume_upgrade_suggestions";

  const getSuggestions = async () => {
    try {
      const queryObj = {
        email_id: user?.email, // Using the logged-in user's email
        file_name: "fileSelect",
        job_id: selectId,
        session_id: logSessionId,
      };

      const response = await fetch(apiEndpointTwo, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryObj),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAiResponse(data.result);
      // setAiready(true);
      // setError(null);
    } catch (error) {
      // setError(error.message);
      // setLoading(false);
    }
  };

  function openAiSuggestion() {
    setAiPopup(true);
    if (sessionToken && user && selectId) {
      getSuggestions();
    }
  }

  function closeAiSuggest() {
    setAiPopup(false);
    setAiResponse(null);
    // setAiready(false);
  }

  const apiEndpointThree = "https://us-east1-foursssolutions.cloudfunctions.net/resume_copy_suggestions";

  const getResumeUpdates = async () => {
    try {
      if (!user?.email) {
        console.error("User email is not available.");
        return; // Exit if user email is not available
      }
      const queryObj = {
        email_id: user?.email, // Using the logged-in user's email
        file_name: "Muni_Duplicate.docx",
        job_id: selectId,
        session_id: logSessionId,
      };

      const response = await fetch(apiEndpointThree, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryObj),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      // setresumeResponse(data.result);
      // setAiready(true);
      // setError(null);
    } catch (error) {
      // setError(error.message);
      // setLoading(false);
    }
  };

  function resumeUpdates() {
    // setResumePopup(true);
    if (sessionToken && user && selectId) {
      getResumeUpdates();
    }
  }
  function closeResumeUpdates() {
    // setResumePopup(false);
    // setresumeResponse(null);
  }

  function copyResponse() {
    if (aiResponse) {
      navigator.clipboard
        .writeText(aiResponse)
        .then(() => {
          console.log("AI response copied to clipboard");
          alert("AI response copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  }
  function copyResumeResponse(response) {
    if (response) {
      navigator.clipboard
        .writeText(response)
        .then(() => {
          console.log("AI response copied to clipboard");
          alert("AI response copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  }

  // const filterByCity = (city) => {
  //   const filteredJobs = jobList.filter((job) => job.job_city === city);
  //   setFilteredJobList(filteredJobs);
  //   setFilter(true);
  //   setActiveFilter(city); // Set active filter to the selected city
  // };

  // const filterByState = (state) => {
  //   const filteredJobs = jobList.filter((job) => job.job_state === state);
  //   setFilteredJobList(filteredJobs);
  //   setFilter(true);
  //   setActiveFilter(state);
  // };

  // const filterRemoteJobs = () => {
  //   const remoteJobs = jobList.filter((job) => job.job_is_remote);
  //   setFilteredJobList(remoteJobs);
  //   setFilter(true);
  //   setActiveFilter("remote"); // Set active filter for remote jobs
  // };

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  //   if (e.target.value === "") {
  //     clearFilter();
  //   } else {
  //     const filteredJobs = jobList.filter(
  //       (job) =>
  //         job.job_title.toLowerCase().includes(e.target.value.toLowerCase()) ||
  //         job.employer_name.toLowerCase().includes(e.target.value.toLowerCase())
  //     );
  //     setFilteredJobList(filteredJobs);
  //     setFilter(true);
  //   }
  // };

  useEffect(() => {
    const displayedJobList = filter ? filteredJobList : jobList;
  }, [filteredJobList, jobList]);

  const displayedJobList = filter ? filteredJobList : jobList;
  const jobCount = displayedJobList.length;

  //beautify responses
  const beautifyAiResponse = (response) => {
    if (!response) return null;

    // Split the response into sections by the headings
    const sections = response.split("### ").filter(Boolean);

    return (
      <>
        {sections.map((section, index) => {
          const lines = section.split("\n").filter((line) => line.trim() !== "");
          const heading = lines.shift(); // Get the first line as heading
          const content = lines.join(" ");

          // Replace ** with <h3> tags
          const formattedHeading = heading.replace(/\*\*(.*?)\*\*/g, "<h3>$1</h3>");
          const formattedContent = content.replace(/\*\*(.*?)\*\*/g, "<h3>$1</h3>");
          const copydata = formattedHeading + formattedContent;

          // Skip rendering if heading matches "Section Order"
          if (/Section Order/i.test(formattedHeading)) {
            return null;
          }

          return (
            <div key={index} className="ai-section">
              <div className="row-flex">
                {formattedHeading && (
                  <h3 className="ai-section-heading" dangerouslySetInnerHTML={{ __html: formattedHeading.trim() }} />
                )}
                <div className="copy-btn" onClick={() => copyResumeResponse(copydata)}>
                  <img src={CopyIcon} alt="icon" />
                </div>
              </div>
              {formattedContent && (
                <div className="ai-section-content">
                  {formattedContent
                    .split("- ")
                    .filter(Boolean)
                    .map((item, idx) => (
                      <div key={idx} className="ai-content-item">
                        <p dangerouslySetInnerHTML={{ __html: item.trim() }} />
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };
  const beautifyResumeResponse = (response) => {
    if (!response) return null;

    // Split the response into sections by the headings
    const sections = response.split("### ").filter(Boolean);

    return (
      <>
        {sections.map((section, index) => {
          const lines = section.split("\n").filter((line) => line.trim() !== "");
          const heading = lines.shift(); // Get the first line as heading
          const content = lines.join(" ");

          // Replace ** with <h3> tags
          const formattedHeading = heading.replace(/\*\*(.*?)\*\*/g, "<h3>$1</h3>");
          const formattedContent = content.replace(/\*\*(.*?)\*\*/g, "<h3>$1</h3>");

          // Skip rendering if heading matches "Section Order"
          if (/Section Order/i.test(formattedHeading)) {
            return null;
          }

          return (
            <div key={index} className="ai-section">
              {formattedHeading && (
                <h3 className="ai-section-heading" dangerouslySetInnerHTML={{ __html: formattedHeading.trim() }} />
              )}
              {formattedContent && (
                <div className="ai-section-content">
                  {formattedContent
                    .split("- ")
                    .filter(Boolean)
                    .map((item, idx) => (
                      <div key={idx} className="ai-content-item">
                        <p dangerouslySetInnerHTML={{ __html: item.trim() }} />
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const handleUserChange = (email) => {
    setCandidate(email);
    let selectedCandidate = candidateList.find((value) => value.primary_email == email);
    setCandidateDetails(selectedCandidate);
  };

  console.log("candidate details ivi", candidateDetails);

  return (
    <>
      {resumeNotFound && (
        <>
          <div className="modal-overlay">
            <div className="modal">
              <h2>Resume not Found</h2>
              {user?.user_role === "recruiter" ? (
                <>
                  <p>
                    Please contact the admin to upload a resume for this candidate <strong>{choosenCandidate}</strong>.
                  </p>
                </>
              ) : (
                <>
                  <p>Resume is not uploaded for this candidate click profile to redirect to profile page.</p>
                </>
              )}

              <div className="button-group">
                <button
                  className="logout-btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Close
                </button>
                {user?.user_role === "recruiter" ? (
                  <></>
                ) : (
                  <>
                    <button
                      className="continue-btn"
                      onClick={() => {
                        profileOpen();
                      }}
                    >
                      Go To Profile
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`content docs col-flex job`}>
        <div className="Jobs-tophead row-flex">
          <div className="head-col col-flex">
            <span>Discover Your Dream Job</span>
            <h2>Explore exciting opportunities that match your skills and aspirations. Let's get started!</h2>
          </div>
          <div className="img-col col-flex">
            <img src={JobBanner} alt="top-banner" className="banner" />
          </div>
        </div>
        <div className="filter row-flex align-center">
          <div className="nav-head">
            <img src={CandidateIcon} alt="filter" className="icon" />
            <div className={`f-nav  ${activeFilters?.primary_email ? "active" : ""}`}>
              <span>{candidateDetails?.full_name}</span>
              <ul className="list col-flex">
                {candidateList.map((list) => (
                  <li
                    className={`${activeFilters?.primary_email == list.primary_email ? "active" : ""}`}
                    key={list.primary_email}
                    onClick={() => handleUserChange(list.primary_email)}
                    onMouseEnter={() => setCandidateId(list.id)}
                  >
                    {list.full_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={`f-nav ${
              isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 1))) ||
              isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 3))) ||
              isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 7)))
                ? "active"
                : ""
            }`}
          >
            <span>Filter Days</span>
            <ul className="list col-flex">
              <li
                className={`${
                  isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 1))) ? "active" : ""
                }`}
                onClick={filterLast24Hours}
              >
                24 Hours
              </li>
              <li
                className={`${
                  isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 3))) ? "active" : ""
                }`}
                onClick={filterLast3Days}
              >
                3 Days
              </li>
              <li
                className={`${
                  isActiveFilter("date", new Date(new Date().setDate(new Date().getDate() - 7))) ? "active" : ""
                }`}
                onClick={filterLast7Days}
              >
                7 Days
              </li>
            </ul>
          </div>
          <div className={`f-nav ${activeFilters?.city ? "active" : ""}`}>
            <span>Filter your City</span>
            <ul className="list col-flex">
              {cityOptions.map(
                (city, index) =>
                  city !== "NULL" && (
                    <li
                      className={`${activeFilters?.city == city ? "active" : ""}`}
                      key={index}
                      onClick={() => filterByCity(city)}
                    >
                      {city}
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className={`f-nav ${activeFilters?.state ? "active" : ""}`}>
            <span>Filter State</span>
            <ul className="list col-flex">
              {stateOptions.map((state) => (
                <li
                  className={`${activeFilters?.state == state ? "active" : ""}`}
                  key={state}
                  onClick={() => filterByState(state)}
                >
                  {state}
                </li>
              ))}
            </ul>
          </div>

          <div className="f-nav">
            <span onClick={filterRemoteJobs} className={activeFilters?.remote ? "active-remote" : ""}>
              Remote Jobs
            </span>
          </div>
          <div className="f-nav">
            <input type="text" placeholder="Search Jobs..." value={searchTerm} onChange={handleSearch} />
          </div>
          <div className="f-nav clear" onClick={clearFilters}>
            <span>Clear Filter</span>
          </div>
          {jobCount > 0 && (
            <div className="job-count">
              <span>
                {jobCount} job{jobCount > 1 ? "s" : ""} Opportunities
              </span>
            </div>
          )}
        </div>

        {displayedJobList.length > 0 ? (
          <div className="job-container row-flex">
            <div className="selection-tab col-flex">
              {displayedJobList.map((list) => (
                <div
                  className={`job-card col-flex ${activeJobId === list.job_id ? "active" : ""}`}
                  onClick={() => handleJobClick(list)}
                >
                  <div className="top row-flex">
                    <span className="comp-name">{list.employer_name}</span>
                    <span className="date">
                      {new Date(list.job_posted_at_datetime_utc).toLocaleDateString("en-US")}
                    </span>
                  </div>
                  <div className="middle">
                    <h3 className="position">{list.job_title}</h3>
                  </div>
                  <div className="bottom row-flex">
                    <div className="location">
                      <span className={`location ${list.job_is_remote ? "active" : ""}`}>
                        {list.job_is_remote ? "Remote" : "Onsite"}
                      </span>{" "}
                      <span className="dot"></span>
                      <span className="text">
                        {list.job_city !== "NULL" ? `${list.job_city}` : ""}{" "}
                        {list.job_state !== "NULL" ? `${list.job_state}` : ""} {list.job_country}
                      </span>
                    </div>
                    {/*<span className={`posted ${list.job_apply_quality_score>0.80? "green":""} ${list.job_apply_quality_score<0.65? "red":""}`}>Score : {(100 * list.job_apply_quality_score).toFixed(0)}%</span>*/}
                  </div>
                </div>
              ))}
            </div>
            <div className="description-tab">
              {content ? (
                <>
                  <div className="top-row row-flex">
                    <div className="info-lhs col-flex">
                      <span className="comp-name">
                        <a href={content.employer_website} target="_blank">
                          {content.employer_name}
                        </a>
                      </span>
                      <h3 className="job-position">{content.job_title}</h3>
                      <h4 className={`location`}>
                        {" "}
                        <span className={` ${content.job_is_remote ? "active" : ""}`}>
                          {" "}
                          {content.job_is_remote ? "Remote" : "OnSite"}{" "}
                        </span>
                        <span className="dot"></span>
                        {content.job_city !== "NULL" ? `${content.job_city}` : ""}{" "}
                        {content.job_state !== "NULL" ? `${content.job_state}` : ""} {content.job_country}
                      </h4>
                      <h4 className="date">
                        Date Posted : {new Date(content.job_posted_at_datetime_utc).toLocaleDateString("en-US")}
                      </h4>
                      {content.job_publisher !== "NULL" && (
                        <h5 className="posted">
                          Job Publisher : <span>{content.job_publisher}</span>
                        </h5>
                      )}
                      <h5 className="posted">
                        Job Employment Type : <span>{content.job_employment_type}</span>
                      </h5>
                    </div>
                    <div className="info-rhs d-flex justify-end align-end">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={content.job_apply_link}
                        target="_blank"
                        onClick={applyButton}
                      >
                        <button className="ai-button">{buttonText} Now</button>
                      </Link>
                    </div>
                  </div>
                  <div className="bot-description col-flex">
                    <h4 className="jshead text-underline">Qualifications</h4>
                    <ul className="job-list">
                      {content?.job_highlights?.Qualifications?.map((value) => (
                        <li>{value}</li>
                      ))}
                    </ul>
                    <h4 className="jshead text-underline">Responsibilities</h4>
                    <ul className="job-list">
                      {content?.job_highlights?.Responsibilities?.map((value) => (
                        <li>{value}</li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <p className="infopara">Select a job to see the details.</p>
              )}
            </div>
          </div>
        ) : (
          <div className="w-100 h-100 d-flex justify-center">
            <Loader />
          </div>
        )}
      </div>
      {aiPopup === true && (
        <>
          <div className="f-screenpoppup job-portal">
            <div className="popup-cont">
              <div className="close" onClick={closeAiSuggest}>
                +
              </div>
              <div className="col-flex">
                <div className="ai-head row-flex">
                  <img src={AiIcon} alt="" className="ai-icon" />
                  Ai Suggestions
                </div>
                <div className={`inner-popup ${aiResponse == null ? "loading" : ""}`}>
                  {/* <h4>
                                    {aiResponse} 
                                    </h4> */}
                  {aiResponse == null ? (
                    <div className="loader-icon">
                      <img src={AiGifIcon} alt="image" />
                    </div>
                  ) : (
                    ""
                  )}
                  {beautifyAiResponse(aiResponse)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActiveJobs;
