import React from 'react'
import './Loader.css';
import loader from './loader.svg';

const Loader = ({style}) => {
  return (
    <div className='loader' style={style}>
        
    </div>
  )
}

export default Loader