import { useEffect, useState } from "react";
import "../assets/styles/OverlayModal.css"; // Importing CSS
import ReactDOM from 'react-dom';

function OverlayModal({ children, isActive }) {
  const [isVisible, setIsVisible] = useState(isActive||false);

  // useEffect(() => {
  //   if (isActive) {
  //     setIsVisible(true); // Show the modal immediately
  //   } else {
  //     // Wait for animation to finish before hiding
  //     setTimeout(() => setIsVisible(false), 400); // 400ms = transition time
  //   }
  // }, [isActive]);

  return ReactDOM.createPortal (
    <div className={`overlay-container ${isActive ? "visible" : ""}`}>
      {isActive && <div className="overlay"></div>}

      {/* Sliding Panel */}
      <div className={`panel ${isVisible ? "open" : ""}`}>
        {children}
      </div>
    </div>,
    document.body
  );
}

export default OverlayModal;